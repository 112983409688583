import React, { Component } from "react"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Training from "./Training"

export class TrainingTab extends Component {
  render() {
    return (
      <Container className="my-5">
        <Row className="align-content-center">
          <Col xl={{span: 3, offset: 0}} lg={{span: 4, offset: 0}} xs={{span: 6, offset: 3}} className="pr-lg-5">
            <Training/>
          </Col>
          <Col xl={9} lg={8}>
            <p className=" lead">
              <em className="text-primary">
                “Training for school staff is an essential part of the continual growth of a school, as it
                facilitates
                the application, within education, of 'current' evidence-based practice in child and adolescent
                development.”
              </em>
            </p>
            <p>
              Given the knowledge basis, experience and continual professional development behind an educational
              psychologist's application and continual review of applied psychology, it is no wonder we play a key role
              in delivering training solutions for school staff! The positive impact of applying psychology can be seen
              at the whole school level, and we thoroughly enjoy it too!
            </p>
          </Col>
        </Row>
        <p>
          At Waite Psychology, we recognise that a school's training solutions vary, so we tailor a package around a
          school's needs. This includes an initial consultation to explore the purpose, content (either bespoke or
          from pre-existing training) and desired outcomes. After training delivery, follow-up consultations are
          made to aid the application of theory into practice.
        </p>
        <p>
          Below is a selection of examples from our most popular training programmes. If you cannot see what you are
          looking for, contact us and we will help you to work it out.
        </p>
        <p>
          Our network of connections to other psychologists and specialists also means that if we cannot provide a
          training solution directly, we will know someone who can!
        </p>
        <Card border="primary" className="mb-5">
          <Card.Header className="bg-primary text-white"><h4>Training programmes to aid whole school practice:</h4>
          </Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>Becoming an 'Attachment Aware and Trauma Responsive' school.</ListGroup.Item>
            <ListGroup.Item>Developing an emotional regulation policy: theory and practice.</ListGroup.Item>
            <ListGroup.Item>Embedding a 'children's rights agenda' within school policy and practice.</ListGroup.Item>
            <ListGroup.Item>The importance of play for everyone: theory and practice.</ListGroup.Item>
            <ListGroup.Item>Developing solution circles as a problem-solving approach for staff.</ListGroup.Item>
          </ListGroup>
        </Card>
          <Card border="secondary" className="mb-5">
            <Card.Header className="bg-secondary text-white"><h4>Training programmes to aid physical and sensory
              development:</h4></Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Motor skills development and intervention.</ListGroup.Item>
              <ListGroup.Item>How to set up and run a sensory room.</ListGroup.Item>
              <ListGroup.Item>The importance of sleep.</ListGroup.Item>
            </ListGroup>
          </Card>
          <Card border="primary" className="mb-5">
            <Card.Header className="bg-primary text-white"><h4>Training programmes to aid cognition and learning:</h4>
            </Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>How to develop executive functioning skills.</ListGroup.Item>
              <ListGroup.Item>Working memory skills and intervention.</ListGroup.Item>
              <ListGroup.Item>Dyslexia and dyscalculia (theory, identification and intervention).</ListGroup.Item>
              <ListGroup.Item>Precision teaching.</ListGroup.Item>
              <ListGroup.Item>Increasing achievement through a growth mindset approach.</ListGroup.Item>
            </ListGroup>
          </Card>
          <Card border="secondary" className="mb-5">
            <Card.Header className="bg-secondary text-white"><h4>Training programmes to aid communication and
              interaction:</h4></Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>Understanding and supporting pupils with Autistic Spectrum Condition
                (ASC).</ListGroup.Item>
              <ListGroup.Item>How to write and use social stories.</ListGroup.Item>
              <ListGroup.Item>How to support language and communication needs within the classroom.</ListGroup.Item>
              <ListGroup.Item>Supporting vocabulary development in the early years and beyond.</ListGroup.Item>
            </ListGroup>
          </Card>
          <Card border="primary" className="mb-5">
            <Card.Header className="bg-primary text-white"><h4>Training programmes to aid social, emotional and mental
              health:</h4></Card.Header>
            <ListGroup variant="flush">
              <ListGroup.Item>How to become a 'sanction free and reward free' classroom.</ListGroup.Item>
              <ListGroup.Item>'Feeding curiosity' to decrease toddler tantrums: why “no” does not work!</ListGroup.Item>
              <ListGroup.Item>Developing emotional problem-solving skills: a relational based approach.</ListGroup.Item>
              <ListGroup.Item>Emotion coaching: theory and practice.</ListGroup.Item>
              <ListGroup.Item>The circle of friends' approach.</ListGroup.Item>
            </ListGroup>
          </Card>
      </Container>
    )
  }
}